import { css } from "@emotion/css";
import * as theme from "../../theme";
import { Dialog } from "@headlessui/react";
import { motion } from "framer-motion";
import { useFragment } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import { Link } from "../../components/HammockRouter/Link";
import ProjectPlaceholder from "./ProjectPlaceholder.svg";
import Overlay from "./overlay.png";
import { RetroPGFShareCardModalContentFragment$key } from "./__generated__/RetroPGFShareCardModalContentFragment.graphql";
import { HStack, VStack } from "../../components/VStack";
import share_card from "./share_card.svg";

export function RetroPGFShareCardModal({
  projectFragmentRef,
}: {
  projectFragmentRef: RetroPGFShareCardModalContentFragment$key;
  closeDialog: () => void;
}) {
  const project = useFragment(
    graphql`
      fragment RetroPGFShareCardModalContentFragment on Project {
        id
        displayName
        profile {
          profileImageUrl
        }
        awarded
      }
    `,
    projectFragmentRef
  );

  return (
    <VStack
      alignItems="center"
      justifyContent="center"
      className={css`
        padding: ${theme.spacing["8"]};
        height: 100%;
      `}
    >
      <Dialog.Panel
        as={motion.div}
        initial={{
          scale: 0.9,
          translateY: theme.spacing["8"],
        }}
        animate={{ translateY: 0, scale: 1 }}
      >
        <Link
          to={`/retropgf/3/application/${parseProjectId(project.id)}`}
          className={css`
            padding: ${theme.spacing["8"]};
            position: relative;
            display: flex;
            width: 800px;
            bottom: 20vh;
            @media (max-width: ${theme.maxWidth.md}) {
              width: 320px;
            }
          `}
        >
          <img
            src={share_card}
            alt="Background"
            className={css`
              position: absolute;
              width: 100%;
              top: 0;
              left: 0;
              z-index: 1;
              border-radius: 16px;
              box-shadow: ${theme.boxShadow.newDefault};
            `}
          />
          <img
            src={Overlay}
            alt={"overlay"}
            className={css`
              position: absolute;
              width: 200px;
              z-index: 2;
              top: 230px;
              left: 32px;
              border-radius: 50%;
              z-index: 1000;
              @media (max-width: ${theme.maxWidth.md}) {
                width: 80px;
                top: 92px;
                left: 13px;
              }
            `}
          />
          <img
            src={project.profile?.profileImageUrl ?? ProjectPlaceholder}
            alt={project.displayName}
            className={css`
              position: absolute;
              width: 180px;
              z-index: 2;
              top: 240px;
              left: 44px;
              border-radius: 50%;
              @media (max-width: ${theme.maxWidth.md}) {
                width: 72px;
                top: 96px;
                left: 17px;
              }
            `}
          />
          <HStack
            className={css`
              position: absolute;
              width: 100%;
              top: 475px;
              left: 0;
              background-color: ${theme.colors.red["100"]};
              z-index: 1000;
              padding: ${theme.spacing["4"]};
              border-radius: 16px;
              box-shadow: ${theme.boxShadow.newDefault};
              @media (max-width: ${theme.maxWidth.md}) {
                top: 200px;
              }
            `}
            justifyContent="space-between"
          >
            <div
              className={css`
                color: ${theme.colors.red["600"]};
              `}
            >
              View project application
            </div>
            <div
              className={css`
                color: ${theme.colors.red["200"]};
              `}
            >
              →
            </div>
          </HStack>
          <VStack
            className={css`
              position: absolute;
              z-index: 2;
              top: 270px;
              left: 250px;
            `}
          >
            <h3
              className={css`
                font-size: ${theme.fontSize["2xl"]};
                @media (max-width: ${theme.maxWidth.md}) {
                  display: none;
                }
              `}
            >
              {project.displayName} received:
            </h3>
            <HStack
              gap="2"
              className={css`
                font-size: ${theme.fontSize["6xl"]};
                @media (max-width: ${theme.maxWidth.md}) {
                  display: none;
                }
              `}
            >
              <h1
                className={css`
                  color: ${theme.colors.black};
                  font-weight: ${theme.fontWeight.bold};
                `}
              >
                {formatNumber(Number(project.awarded))}
              </h1>
              <h1
                className={css`
                  color: ${theme.colors.red["600"]};
                  font-weight: ${theme.fontWeight.bold};
                `}
              >
                OP
              </h1>
            </HStack>
          </VStack>
        </Link>
      </Dialog.Panel>
    </VStack>
  );
}

function parseProjectId(projectId: string) {
  return projectId.split("|")[1];
}

function formatNumber(number: number) {
  const numberFormat = new Intl.NumberFormat("en", {
    style: "decimal",
    useGrouping: true,
  });

  const parts = numberFormat.formatToParts(number);
  return parts
    .filter((part) => part.type !== "currency" && part.type !== "literal")
    .map((part) => part.value)
    .join("");
}
