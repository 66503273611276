/**
 * @generated SignedSource<<c394bdb09357fea5967b6af4df988463>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ApprovalCastVoteButtonDelegateFragment$data = {
  readonly delegate?: {
    readonly statement: {
      readonly __typename: "DelegateStatement";
    } | null;
    readonly tokensRepresentedSnapshot: {
      readonly amount: {
        readonly " $fragmentSpreads": FragmentRefs<"TokenAmountDisplayFragment">;
      };
    };
    readonly votes: ReadonlyArray<{
      readonly proposal: {
        readonly id: string;
      };
    }>;
  };
  readonly " $fragmentType": "ApprovalCastVoteButtonDelegateFragment";
};
export type ApprovalCastVoteButtonDelegateFragment$key = {
  readonly " $data"?: ApprovalCastVoteButtonDelegateFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ApprovalCastVoteButtonDelegateFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "address"
    },
    {
      "kind": "RootArgument",
      "name": "proposalId"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "skipAddress"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ApprovalCastVoteButtonDelegateFragment",
  "selections": [
    {
      "condition": "skipAddress",
      "kind": "Condition",
      "passingValue": false,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "addressOrEnsName",
              "variableName": "address"
            }
          ],
          "concreteType": "Delegate",
          "kind": "LinkedField",
          "name": "delegate",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "DelegateStatement",
              "kind": "LinkedField",
              "name": "statement",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Vote",
              "kind": "LinkedField",
              "name": "votes",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Proposal",
                  "kind": "LinkedField",
                  "name": "proposal",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "id",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": [
                {
                  "kind": "Variable",
                  "name": "proposalId",
                  "variableName": "proposalId"
                }
              ],
              "concreteType": "VotingPower",
              "kind": "LinkedField",
              "name": "tokensRepresentedSnapshot",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "TokenAmount",
                  "kind": "LinkedField",
                  "name": "amount",
                  "plural": false,
                  "selections": [
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "TokenAmountDisplayFragment"
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ]
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "09610bff6238f8fac87fbe8e4c662260";

export default node;
