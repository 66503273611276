/**
 * @generated SignedSource<<e86c561b72d6bb444cd14653646ad50d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RetroPGFAddListToBallotModalContentFragment$data = {
  readonly id: string;
  readonly listContent: ReadonlyArray<{
    readonly OPAmount: number;
    readonly project: {
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"RetroPGFModalApplicationRowFragment">;
    };
  }>;
  readonly listName: string;
  readonly " $fragmentType": "RetroPGFAddListToBallotModalContentFragment";
};
export type RetroPGFAddListToBallotModalContentFragment$key = {
  readonly " $data"?: RetroPGFAddListToBallotModalContentFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"RetroPGFAddListToBallotModalContentFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RetroPGFAddListToBallotModalContentFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "listName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ListContent",
      "kind": "LinkedField",
      "name": "listContent",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Project",
          "kind": "LinkedField",
          "name": "project",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "RetroPGFModalApplicationRowFragment"
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "OPAmount",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "List",
  "abstractKey": null
};
})();

(node as any).hash = "80537cf2639543735afb25aa5019b413";

export default node;
