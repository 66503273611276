/**
 * @generated SignedSource<<73fca7a1ea361ad2e9e304fb6fd2bcfe>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type VoteDetailsFragment$data = {
  readonly options: ReadonlyArray<{
    readonly description: string;
  }> | null;
  readonly proposal: {
    readonly number: string;
    readonly proposalData: {
      readonly __typename: string;
    };
    readonly title: string;
    readonly totalValue: string;
  };
  readonly reason: string;
  readonly supportDetailed: number;
  readonly transaction: {
    readonly block: {
      readonly timestamp: number;
    };
  };
  readonly votes: {
    readonly amount: {
      readonly amount: string;
      readonly decimals: number;
    };
  };
  readonly " $fragmentSpreads": FragmentRefs<"VoteReasonFragment">;
  readonly " $fragmentType": "VoteDetailsFragment";
};
export type VoteDetailsFragment$key = {
  readonly " $data"?: VoteDetailsFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"VoteDetailsFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "VoteDetailsFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "reason",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "VoteReasonFragment"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "supportDetailed",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "VotingPower",
      "kind": "LinkedField",
      "name": "votes",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "TokenAmount",
          "kind": "LinkedField",
          "name": "amount",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "amount",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "decimals",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Transaction",
      "kind": "LinkedField",
      "name": "transaction",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Block",
          "kind": "LinkedField",
          "name": "block",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "timestamp",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ApprovalVotingProposalOption",
      "kind": "LinkedField",
      "name": "options",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "description",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Proposal",
      "kind": "LinkedField",
      "name": "proposal",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "number",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalValue",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "proposalData",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "__typename",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Vote",
  "abstractKey": null
};

(node as any).hash = "b1b3dec3a8536b49330b335f509e20c7";

export default node;
