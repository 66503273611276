/**
 * @generated SignedSource<<662de8df794a7bd8c1ef7494597aa23b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProjectCategory = "COLLECTIVE_GOVERNANCE" | "DEVELOPER_ECOSYSTEM" | "END_USER_EXPERIENCE_AND_ADOPTION" | "OP_STACK" | "%future added value";
export type ProjectOrder = "alphabeticalAZ" | "alphabeticalZA" | "byIncludedInBallots" | "mostAwarded" | "mostInBallots" | "shuffle" | "%future added value";
export type RetroPGFResultsContainerPaginationQuery$variables = {
  after?: string | null;
  category?: ReadonlyArray<ProjectCategory> | null;
  first?: number | null;
  orderBy?: ProjectOrder | null;
  search?: string | null;
  seed?: string | null;
};
export type RetroPGFResultsContainerPaginationQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"RetroPGFResultsContainerFragment">;
};
export type RetroPGFResultsContainerPaginationQuery = {
  response: RetroPGFResultsContainerPaginationQuery$data;
  variables: RetroPGFResultsContainerPaginationQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "after"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "category"
  },
  {
    "defaultValue": 30,
    "kind": "LocalArgument",
    "name": "first"
  },
  {
    "defaultValue": "alphabeticalAZ",
    "kind": "LocalArgument",
    "name": "orderBy"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "search"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "seed"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "category",
    "variableName": "category"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "orderBy",
    "variableName": "orderBy"
  },
  {
    "kind": "Variable",
    "name": "search",
    "variableName": "search"
  },
  {
    "kind": "Variable",
    "name": "seed",
    "variableName": "seed"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "address",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RetroPGFResultsContainerPaginationQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "RetroPGFResultsContainerFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RetroPGFResultsContainerPaginationQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "RetroPGF",
        "kind": "LinkedField",
        "name": "retroPGF",
        "plural": false,
        "selections": [
          {
            "alias": "applications",
            "args": (v1/*: any*/),
            "concreteType": "ProjectsConnection",
            "kind": "LinkedField",
            "name": "projects",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ProjectsEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Project",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "displayName",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "impactCategory",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Delegate",
                        "kind": "LinkedField",
                        "name": "applicant",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Address",
                            "kind": "LinkedField",
                            "name": "address",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "ResolvedName",
                                "kind": "LinkedField",
                                "name": "resolvedName",
                                "plural": false,
                                "selections": [
                                  (v3/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "name",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "OptimistProfile",
                        "kind": "LinkedField",
                        "name": "profile",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "profileImageUrl",
                            "storageKey": null
                          },
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "includedInBallots",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "includedInLists",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "awarded",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": "applications",
            "args": (v1/*: any*/),
            "filters": [
              "orderBy",
              "category",
              "seed",
              "search"
            ],
            "handle": "connection",
            "key": "RetroPGFResultsContainerFragment_applications",
            "kind": "LinkedHandle",
            "name": "projects"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "261d8015cd48d7e72160b489be2ac7ff",
    "id": null,
    "metadata": {},
    "name": "RetroPGFResultsContainerPaginationQuery",
    "operationKind": "query",
    "text": "query RetroPGFResultsContainerPaginationQuery(\n  $after: String\n  $category: [ProjectCategory!]\n  $first: Int = 30\n  $orderBy: ProjectOrder = alphabeticalAZ\n  $search: String\n  $seed: String\n) {\n  ...RetroPGFResultsContainerFragment_2di8uq\n}\n\nfragment NounResolvedLinkFragment on ResolvedName {\n  address\n  ...NounResolvedNameFragment\n}\n\nfragment NounResolvedNameFragment on ResolvedName {\n  address\n  name\n}\n\nfragment RetroPGFResultsContainerApplicationRowFragment on Project {\n  id\n  displayName\n  impactCategory\n  applicant {\n    address {\n      address\n      resolvedName {\n        ...NounResolvedLinkFragment\n      }\n    }\n    id\n  }\n  profile {\n    profileImageUrl\n    id\n  }\n  includedInBallots\n  includedInLists\n  awarded\n  ...RetroPGFShareCardModalContentFragment\n}\n\nfragment RetroPGFResultsContainerFragment_2di8uq on Query {\n  retroPGF {\n    applications: projects(first: $first, after: $after, orderBy: $orderBy, category: $category, seed: $seed, search: $search) {\n      edges {\n        node {\n          id\n          ...RetroPGFResultsContainerApplicationRowFragment\n          __typename\n        }\n        cursor\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n}\n\nfragment RetroPGFShareCardModalContentFragment on Project {\n  id\n  displayName\n  profile {\n    profileImageUrl\n    id\n  }\n  awarded\n}\n"
  }
};
})();

(node as any).hash = "e479efe469eeddb45b50fb4f649eb7cb";

export default node;
