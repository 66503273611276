/**
 * @generated SignedSource<<a0ed7b234e2d8ce60ddb0052c2e92970>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RetroPGFModalApplicationRowFragment$data = {
  readonly bio: string;
  readonly displayName: string;
  readonly profile: {
    readonly profileImageUrl: string | null;
  } | null;
  readonly " $fragmentType": "RetroPGFModalApplicationRowFragment";
};
export type RetroPGFModalApplicationRowFragment$key = {
  readonly " $data"?: RetroPGFModalApplicationRowFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"RetroPGFModalApplicationRowFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RetroPGFModalApplicationRowFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "bio",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "OptimistProfile",
      "kind": "LinkedField",
      "name": "profile",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "profileImageUrl",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Project",
  "abstractKey": null
};

(node as any).hash = "408fdcc556666cc0512f25db52d3bba7";

export default node;
