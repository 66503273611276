import { css } from "@emotion/css";
import { useCallback } from "react";
import { motion } from "framer-motion";
import { HStack, VStack } from "../../components/VStack";
import * as theme from "../../theme";
import { useFragment, usePaginationFragment } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import InfiniteScroll from "react-infinite-scroller";
import ProjectPlaceholder from "./ProjectPlaceholder.svg";
import { RetroPGFResultsContainerFragment$key } from "./__generated__/RetroPGFResultsContainerFragment.graphql";
import { RetroPGFResultsContainerApplicationRowFragment$key } from "./__generated__/RetroPGFResultsContainerApplicationRowFragment.graphql";
import { NounResolvedLink } from "../../components/NounResolvedLink";
import { icons } from "../../icons/icons";
import { useStartTransition } from "../../components/HammockRouter/HammockRouter";
import { useOpenDialog } from "../../components/DialogProvider/DialogProvider";

export const RetroPGFResultsContainer = ({
  fragmentKey,
  isPending,
}: {
  fragmentKey: RetroPGFResultsContainerFragment$key;
  isPending?: boolean;
}) => {
  const {
    data: {
      retroPGF: { applications },
    },
    loadNext,
    hasNext,
    isLoadingNext,
  } = usePaginationFragment(
    graphql`
      fragment RetroPGFResultsContainerFragment on Query
      @argumentDefinitions(
        first: { type: "Int", defaultValue: 30 }
        after: { type: "String" }
        orderBy: { type: "ProjectOrder", defaultValue: alphabeticalAZ }
        category: { type: "[ProjectCategory!]" }
        seed: { type: "String" }
        search: { type: "String" }
      )
      @refetchable(queryName: "RetroPGFResultsContainerPaginationQuery") {
        retroPGF {
          applications: projects(
            first: $first
            after: $after
            orderBy: $orderBy
            category: $category
            seed: $seed
            search: $search
          ) @connection(key: "RetroPGFResultsContainerFragment_applications") {
            edges {
              node {
                id
                ...RetroPGFResultsContainerApplicationRowFragment
              }
            }
          }
        }
      }
    `,
    fragmentKey
  );

  const loadMore = useCallback(() => {
    loadNext(30);
  }, [loadNext]);

  return (
    <motion.div
      initial={{ opacity: 1 }}
      animate={{ opacity: isPending ? 0.3 : 1 }}
      transition={{ duration: 0.3, delay: isPending ? 0.3 : 0 }}
      className={css`
        width: 100%;
        /* max-width: ${theme.maxWidth["6xl"]}; */
      `}
    >
      <InfiniteScroll loadMore={loadMore} hasMore={hasNext}>
        <div
          className={css`
            box-shadow: ${theme.boxShadow.newDefault};
            border-radius: ${theme.borderRadius.xl};
            border: 1px solid ${theme.colors.gray.eb};
            display: grid;
            grid-template-columns: 1fr;
            background: ${theme.colors.white};

            & a:first-child > div {
              border-top: 0px;
            }
          `}
        >
          <div
            className={css`
              height: 100%;
              padding: ${theme.spacing["6"]} ${theme.spacing["6"]}
                ${theme.spacing["4"]} ${theme.spacing["6"]};
              font-size: ${theme.fontSize.sm};
              color: ${theme.colors.gray[700]};
              line-height: ${theme.lineHeight.tight};
              border-bottom: 1px solid ${theme.colors.gray.eb};
            `}
          >
            <HStack
              alignItems="center"
              className={css`
                display: grid;
                grid-template-columns: 2.5fr 1fr 2.5fr 0.5fr 0.5fr 1fr;
                gap: ${theme.spacing["6"]};
                @media (max-width: ${theme.maxWidth["4xl"]}) {
                  grid-template-columns: 1.5fr 1fr 1fr 0.5fr 1fr;
                }
                @media (max-width: ${theme.maxWidth["2xl"]}) {
                  grid-template-columns: 1fr 1fr;
                  overflow-y: scroll;
                }
              `}
            >
              <h3>Project</h3>
              <h3
                className={css`
                  @media (max-width: ${theme.maxWidth["2xl"]}) {
                    display: none;
                  }
                `}
              >
                Submitted by
              </h3>
              <h3
                className={css`
                  @media (max-width: ${theme.maxWidth["4xl"]}) {
                    display: none;
                  }
                `}
              >
                Categories
              </h3>
              <div
                className={css`
                  display: flex;
                  justify-content: right;
                  @media (max-width: ${theme.maxWidth["2xl"]}) {
                    display: none;
                  }
                `}
              >
                <h3>In ballots</h3>
              </div>
              <div
                className={css`
                  display: flex;
                  justify-content: right;
                  @media (max-width: ${theme.maxWidth["2xl"]}) {
                    display: none;
                  }
                `}
              >
                <h3>In lists</h3>
              </div>
              <div
                className={css`
                  display: flex;
                  justify-content: right;
                `}
              >
                <h3>Amount received</h3>
              </div>
            </HStack>
          </div>
          {applications.edges.map((application) => (
            <ApplicationRow
              key={application.node.id}
              fragmentRef={application.node}
            />
          ))}
        </div>
      </InfiniteScroll>

      {isLoadingNext && (
        <HStack
          justifyContent="center"
          className={css`
            padding-top: ${theme.spacing["16"]};
          `}
        >
          Loading...
        </HStack>
      )}
    </motion.div>
  );
};

const ApplicationRow = ({
  fragmentRef,
}: {
  fragmentRef: RetroPGFResultsContainerApplicationRowFragment$key;
}) => {
  const startTransition = useStartTransition();
  const openDialog = useOpenDialog();

  const project = useFragment(
    graphql`
      fragment RetroPGFResultsContainerApplicationRowFragment on Project {
        id
        displayName
        impactCategory
        applicant {
          address {
            address
            resolvedName {
              ...NounResolvedLinkFragment
            }
          }
        }
        profile {
          profileImageUrl
        }
        includedInBallots
        includedInLists
        awarded

        # eslint-disable-next-line relay/must-colocate-fragment-spreads
        ...RetroPGFShareCardModalContentFragment
      }
    `,
    fragmentRef
  );

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        startTransition(() =>
          openDialog({
            type: "RPGF_SHARE_CARD",
            params: { projectFragmentRef: project },
          })
        );
      }}
      className={css`
        display: flex;
        flex-direction: column;
      `}
    >
      <VStack
        gap="4"
        className={css`
          height: 100%;
          padding: ${theme.spacing["3"]} ${theme.spacing["6"]};
          cursor: pointer;
          font-size: ${theme.fontSize.sm};
          line-height: ${theme.lineHeight.tight};
          border-bottom: 1px solid ${theme.colors.gray.eb};
        `}
      >
        <HStack
          alignItems="center"
          className={css`
            display: grid;
            grid-template-columns: 2.5fr 1fr 2.5fr 0.5fr 0.5fr 1fr;
            gap: ${theme.spacing["6"]};
            @media (max-width: ${theme.maxWidth["4xl"]}) {
              grid-template-columns: 1.5fr 1fr 1fr 0.5fr 1fr;
            }
            @media (max-width: ${theme.maxWidth["2xl"]}) {
              grid-template-columns: 1fr 1fr;
              overflow-y: scroll;
            }
          `}
        >
          <HStack
            gap="2"
            className={css`
              position: relative;
              align-items: center;
            `}
          >
            <img
              src={project.profile?.profileImageUrl ?? ProjectPlaceholder}
              alt={`${project.displayName} icon`}
              className={css`
                height: 30px;
                width: 30px;
                outline: 2px solid #fff;
                border-radius: 4px;
                box-shadow: ${theme.boxShadow.newDefault};
                background: #fff;
                left: 24px;
                top: calc(33% - 25px);
                z-index: 2;
              `}
            />

            <h3
              className={css`
                font-weight: ${theme.fontWeight.medium};
              `}
            >
              {project.displayName}
            </h3>
          </HStack>

          <HStack
            className={css`
              @media (max-width: ${theme.maxWidth["2xl"]}) {
                display: none;
              }
            `}
          >
            <img
              src={icons.profile}
              alt="user icon"
              className={css`
                height: 15px;
                width: 15px;
                margin-right: ${theme.spacing["2"]};
              `}
            />
            <NounResolvedLink
              resolvedName={project.applicant.address.resolvedName}
            />
          </HStack>

          <div
            className={css`
              @media (max-width: ${theme.maxWidth["4xl"]}) {
                display: none;
              }
            `}
          >
            <CategoryListItem categories={project.impactCategory} />
          </div>

          <div
            className={css`
              display: flex;
              justify-content: right;
              @media (max-width: ${theme.maxWidth["2xl"]}) {
                display: none;
              }
            `}
          >
            <h3
              className={css`
                margin-top: ${theme.spacing["2"]};
              `}
            >
              {project.includedInBallots}
            </h3>
          </div>

          <div
            className={css`
              display: flex;
              justify-content: right;
              @media (max-width: ${theme.maxWidth["2xl"]}) {
                display: none;
              }
            `}
          >
            <h3
              className={css`
                margin-top: ${theme.spacing["2"]};
              `}
            >
              {project.includedInLists}
            </h3>
          </div>

          <div
            className={css`
              display: flex;
              text-align: right;
              justify-content: right;
            `}
          >
            <h3
              className={css`
                margin-top: ${theme.spacing["2"]};
              `}
            >
              <VStack gap="1">
                <span
                  className={css`
                    font-weight: ${theme.fontWeight.medium};
                  `}
                >
                  {formatNumber(Number(project.awarded))} OP
                </span>{" "}
                <span
                  className={css`
                    color: ${theme.colors.gray[700]};
                  `}
                >
                  ({formatShare(Number(project.awarded) / 300_000)}%)
                </span>
              </VStack>
            </h3>
          </div>
        </HStack>
      </VStack>
    </div>
  );
};

function formatShare(value: number) {
  return value.toLocaleString("en-US", {
    maximumFractionDigits: 2,
  });
}

function formatNumber(number: number) {
  const numberFormat = new Intl.NumberFormat("en", {
    style: "decimal",
    useGrouping: true,
  });

  const parts = numberFormat.formatToParts(number);
  return parts
    .filter((part) => part.type !== "currency" && part.type !== "literal")
    .map((part) => part.value)
    .join("");
}

const CategoryListItem = ({
  categories,
}: {
  categories: readonly string[];
}) => {
  const moreCategoriesExist = categories.length > 2;

  return (
    <HStack
      className={css`
        display: flex;
        flex-wrap: nowrap;
      `}
    >
      {categories.slice(0, 2).map((category) => (
        <div
          key={category}
          className={css`
            background: ${theme.colors.gray["fa"]};
            gap: 0;
            font-size: ${theme.fontSize.xs};
            color: ${theme.colors.gray[700]};
            white-space: nowrap;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: ${theme.borderRadius.full};
            margin-top: ${theme.spacing["2"]};
            padding: 0 ${theme.spacing["2"]};
          `}
        >
          {formatCategory(category)}
        </div>
      ))}
      {moreCategoriesExist && (
        <div
          className={css`
            background: ${theme.colors.gray["fa"]};
            gap: 0;
            font-size: ${theme.fontSize.xs};
            color: ${theme.colors.gray[700]};
            white-space: nowrap;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: ${theme.borderRadius.full};
            margin-top: ${theme.spacing["2"]};
            padding: 0 ${theme.spacing["2"]};
          `}
        >
          More
        </div>
      )}
    </HStack>
  );
};

function formatCategory(category: string) {
  switch (category) {
    case "OP_STACK":
      return "OP Stack";
    case "END_USER_EXPERIENCE_AND_ADOPTION":
      return "End UX & Adoption";
    default:
      return category
        .split("_")
        .map(
          (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        )
        .join(" ");
  }
}
