/**
 * @generated SignedSource<<5d3c48a71a5a7ed4793be39c9a1b02d5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ApprovalCastVoteDialogFragment$data = {
  readonly options: ReadonlyArray<{
    readonly budgetTokensSpent: {
      readonly amount: string;
      readonly " $fragmentSpreads": FragmentRefs<"TokenAmountDisplayFragment">;
    };
    readonly description: string;
  }>;
  readonly settings: {
    readonly maxApprovals: number;
  };
  readonly " $fragmentType": "ApprovalCastVoteDialogFragment";
};
export type ApprovalCastVoteDialogFragment$key = {
  readonly " $data"?: ApprovalCastVoteDialogFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ApprovalCastVoteDialogFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ApprovalCastVoteDialogFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ApprovalVotingProposalOption",
      "kind": "LinkedField",
      "name": "options",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "description",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "TokenAmount",
          "kind": "LinkedField",
          "name": "budgetTokensSpent",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "amount",
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "TokenAmountDisplayFragment"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ApprovalVotingProposalSettings",
      "kind": "LinkedField",
      "name": "settings",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "maxApprovals",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ApprovalVotingProposalData",
  "abstractKey": null
};

(node as any).hash = "204be5df10b1a2333c0f8fcd71b05b20";

export default node;
