/**
 * @generated SignedSource<<fc68a2c883e0bce2dc90b70288e13f81>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ForAgainstAbstainRowFragment$data = {
  readonly delegateMetrics: {
    readonly abstainVotes: number;
    readonly againstVotes: number;
    readonly forVotes: number;
  };
  readonly " $fragmentType": "ForAgainstAbstainRowFragment";
};
export type ForAgainstAbstainRowFragment$key = {
  readonly " $data"?: ForAgainstAbstainRowFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ForAgainstAbstainRowFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ForAgainstAbstainRowFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "DelegateMetrics",
      "kind": "LinkedField",
      "name": "delegateMetrics",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "forVotes",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "againstVotes",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "abstainVotes",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Delegate",
  "abstractKey": null
};

(node as any).hash = "d5426fd0066ca39c54b76d2fd1a9ba6f";

export default node;
