/**
 * @generated SignedSource<<5ae52bb04c56403e686e71877847b008>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RetroPGFShareCardModalContentFragment$data = {
  readonly awarded: string;
  readonly displayName: string;
  readonly id: string;
  readonly profile: {
    readonly profileImageUrl: string | null;
  } | null;
  readonly " $fragmentType": "RetroPGFShareCardModalContentFragment";
};
export type RetroPGFShareCardModalContentFragment$key = {
  readonly " $data"?: RetroPGFShareCardModalContentFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"RetroPGFShareCardModalContentFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RetroPGFShareCardModalContentFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "OptimistProfile",
      "kind": "LinkedField",
      "name": "profile",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "profileImageUrl",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "awarded",
      "storageKey": null
    }
  ],
  "type": "Project",
  "abstractKey": null
};

(node as any).hash = "e7c8852819b00c7a406586268bbca02f";

export default node;
