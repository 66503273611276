import ballot from "./ballot.svg";
import community from "./community.svg";
import measure from "./measure.svg";
import pedestrian from "./pedestrian.svg";
import discord from "./discord.svg";
import twitter from "./twitter.svg";
import piggyBank from "./piggyBank.svg";
import speakerCone from "./speakerCone.svg";
import anonNoun from "./anonNoun.svg";
import expand from "./expand.svg";
import walletConnected from "./walletConnected.svg";
import wallet from "./wallet.svg";
import spinner from "./spinner.svg";
import power from "./power.svg";
import badge from "./badge.svg";
import op from "./op.svg";
import github from "./github.svg";
import world from "./world.svg";
import bin from "./bin.svg";
import search from "./search.svg";
import heart from "./heart.svg";
import check from "./check.svg";
import checkGrey from "./checkGrey.svg";
import heartRed from "./heartRed.svg";
import scroll from "./scroll.svg";
import close from "./close.svg";
import profile from "./profile.svg";

export const icons = {
  expand,
  anonNoun,
  speakerCone,
  piggyBank,
  discord,
  twitter,
  ballot,
  community,
  measure,
  pedestrian,
  walletConnected,
  wallet,
  spinner,
  power,
  badge,
  op,
  github,
  world,
  bin,
  search,
  heart,
  check,
  checkGrey,
  heartRed,
  scroll,
  close,
  profile,
};
