/**
 * @generated SignedSource<<de8ebc58f7a3408d7fd133223dd67bc0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ProposalStatus = "ACTIVE" | "CANCELLED" | "DEFEATED" | "EXECUTED" | "EXPIRED" | "PENDING" | "QUEUED" | "SUCCEEDED" | "VETOED" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ApprovalCastVoteButtonFragment$data = {
  readonly id: string;
  readonly number: string;
  readonly status: ProposalStatus;
  readonly " $fragmentType": "ApprovalCastVoteButtonFragment";
};
export type ApprovalCastVoteButtonFragment$key = {
  readonly " $data"?: ApprovalCastVoteButtonFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ApprovalCastVoteButtonFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ApprovalCastVoteButtonFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "number",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    }
  ],
  "type": "Proposal",
  "abstractKey": null
};

(node as any).hash = "bc2020af71688976ade2653549e93b73";

export default node;
