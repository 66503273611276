/**
 * @generated SignedSource<<94d1aadce0c2137193482bb0910f3d2a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PastProposalsFormSectionProposalListFragment$data = {
  readonly allProposals: ReadonlyArray<{
    readonly number: string;
    readonly title: string;
  }>;
  readonly " $fragmentType": "PastProposalsFormSectionProposalListFragment";
};
export type PastProposalsFormSectionProposalListFragment$key = {
  readonly " $data"?: PastProposalsFormSectionProposalListFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"PastProposalsFormSectionProposalListFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PastProposalsFormSectionProposalListFragment",
  "selections": [
    {
      "alias": "allProposals",
      "args": null,
      "concreteType": "Proposal",
      "kind": "LinkedField",
      "name": "proposals",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "number",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "22ad2fb5de422c98ae17d7100a613693";

export default node;
