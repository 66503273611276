/**
 * @generated SignedSource<<62e2e03d9ecec9cecbbeb76ad581454c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type VoteReasonFragment$data = {
  readonly reason: string;
  readonly " $fragmentType": "VoteReasonFragment";
};
export type VoteReasonFragment$key = {
  readonly " $data"?: VoteReasonFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"VoteReasonFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "VoteReasonFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "reason",
      "storageKey": null
    }
  ],
  "type": "Vote",
  "abstractKey": null
};

(node as any).hash = "4122b1393dc7b2f923ee56613c9d9f99";

export default node;
