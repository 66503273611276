/**
 * @generated SignedSource<<ee039b0e308fcf7ab0007b179abbcbd8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CitizensContainerFragment$data = {
  readonly retroPGF: {
    readonly voters: ReadonlyArray<{
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"VoterCardFragment">;
    }>;
  };
  readonly " $fragmentType": "CitizensContainerFragment";
};
export type CitizensContainerFragment$key = {
  readonly " $data"?: CitizensContainerFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CitizensContainerFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": "mostVotingPower",
      "kind": "LocalArgument",
      "name": "orderBy"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "CitizensContainerFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "RetroPGF",
      "kind": "LinkedField",
      "name": "retroPGF",
      "plural": false,
      "selections": [
        {
          "alias": "voters",
          "args": [
            {
              "kind": "Variable",
              "name": "orderBy",
              "variableName": "orderBy"
            }
          ],
          "concreteType": "Delegate",
          "kind": "LinkedField",
          "name": "badgeholders",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "VoterCardFragment"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "2481529f570479fc8e3f3ac2d4b4664d";

export default node;
