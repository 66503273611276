/**
 * @generated SignedSource<<5778011a2dcbeaf4b3e77cb12b9b1dc9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DelegateProfileImageFragment$data = {
  readonly address: {
    readonly resolvedName: {
      readonly address: string;
      readonly " $fragmentSpreads": FragmentRefs<"ENSAvatarFragment" | "NounResolvedNameFragment">;
    };
  };
  readonly isCitizen: boolean;
  readonly tokensRepresented: {
    readonly amount: {
      readonly " $fragmentSpreads": FragmentRefs<"TokenAmountDisplayFragment">;
    };
  };
  readonly " $fragmentType": "DelegateProfileImageFragment";
};
export type DelegateProfileImageFragment$key = {
  readonly " $data"?: DelegateProfileImageFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"DelegateProfileImageFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DelegateProfileImageFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Address",
      "kind": "LinkedField",
      "name": "address",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ResolvedName",
          "kind": "LinkedField",
          "name": "resolvedName",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "address",
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "NounResolvedNameFragment"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "ENSAvatarFragment"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "VotingPower",
      "kind": "LinkedField",
      "name": "tokensRepresented",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "TokenAmount",
          "kind": "LinkedField",
          "name": "amount",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "TokenAmountDisplayFragment"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isCitizen",
      "storageKey": null
    }
  ],
  "type": "Delegate",
  "abstractKey": null
};

(node as any).hash = "69ce0882b6a07f04b9f4017497e2c28b";

export default node;
