/**
 * @generated SignedSource<<fa2bd00c1798fe7d85a79db9c320cc40>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProfileDropDownButtonFragment$data = {
  readonly address: {
    readonly resolvedName: {
      readonly address: string;
      readonly name: string | null;
      readonly " $fragmentSpreads": FragmentRefs<"ENSAvatarFragment" | "NounResolvedNameFragment">;
    };
  };
  readonly amountOwned: {
    readonly amount: {
      readonly " $fragmentSpreads": FragmentRefs<"TokenAmountDisplayFragment">;
    };
  };
  readonly delegateMetrics: {
    readonly tokenHoldersRepresentedCount: number;
  };
  readonly delegatingTo: {
    readonly address: {
      readonly resolvedName: {
        readonly address: string;
        readonly name: string | null;
        readonly " $fragmentSpreads": FragmentRefs<"ENSAvatarFragment" | "NounResolvedNameFragment">;
      };
    };
  };
  readonly tokensRepresented: {
    readonly amount: {
      readonly " $fragmentSpreads": FragmentRefs<"TokenAmountDisplayFragment">;
    };
  };
  readonly " $fragmentType": "ProfileDropDownButtonFragment";
};
export type ProfileDropDownButtonFragment$key = {
  readonly " $data"?: ProfileDropDownButtonFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProfileDropDownButtonFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "concreteType": "Address",
  "kind": "LinkedField",
  "name": "address",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ResolvedName",
      "kind": "LinkedField",
      "name": "resolvedName",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "address",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "NounResolvedNameFragment"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ENSAvatarFragment"
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "TokenAmount",
    "kind": "LinkedField",
    "name": "amount",
    "plural": false,
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "TokenAmountDisplayFragment"
      }
    ],
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProfileDropDownButtonFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Delegate",
      "kind": "LinkedField",
      "name": "delegatingTo",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "VotingPower",
      "kind": "LinkedField",
      "name": "amountOwned",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "VotingPower",
      "kind": "LinkedField",
      "name": "tokensRepresented",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "DelegateMetrics",
      "kind": "LinkedField",
      "name": "delegateMetrics",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "tokenHoldersRepresentedCount",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Delegate",
  "abstractKey": null
};
})();

(node as any).hash = "55d1f302a4baaef0746b508155260ee7";

export default node;
