/**
 * @generated SignedSource<<438fb7e2708c8287d080f4c293a86943>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RetroPGFResultsContainerApplicationRowFragment$data = {
  readonly applicant: {
    readonly address: {
      readonly address: string;
      readonly resolvedName: {
        readonly " $fragmentSpreads": FragmentRefs<"NounResolvedLinkFragment">;
      };
    };
  };
  readonly awarded: string;
  readonly displayName: string;
  readonly id: string;
  readonly impactCategory: ReadonlyArray<string>;
  readonly includedInBallots: number;
  readonly includedInLists: number;
  readonly profile: {
    readonly profileImageUrl: string | null;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"RetroPGFShareCardModalContentFragment">;
  readonly " $fragmentType": "RetroPGFResultsContainerApplicationRowFragment";
};
export type RetroPGFResultsContainerApplicationRowFragment$key = {
  readonly " $data"?: RetroPGFResultsContainerApplicationRowFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"RetroPGFResultsContainerApplicationRowFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RetroPGFResultsContainerApplicationRowFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "impactCategory",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Delegate",
      "kind": "LinkedField",
      "name": "applicant",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Address",
          "kind": "LinkedField",
          "name": "address",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "address",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "ResolvedName",
              "kind": "LinkedField",
              "name": "resolvedName",
              "plural": false,
              "selections": [
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "NounResolvedLinkFragment"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "OptimistProfile",
      "kind": "LinkedField",
      "name": "profile",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "profileImageUrl",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "includedInBallots",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "includedInLists",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "awarded",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RetroPGFShareCardModalContentFragment"
    }
  ],
  "type": "Project",
  "abstractKey": null
};

(node as any).hash = "09d55c8ebecc3dd926707dfe77bd196b";

export default node;
