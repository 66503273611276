/**
 * @generated SignedSource<<18eb9bba37b34694bbc5d9770119d510>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProjectCategory = "COLLECTIVE_GOVERNANCE" | "DEVELOPER_ECOSYSTEM" | "END_USER_EXPERIENCE_AND_ADOPTION" | "OP_STACK" | "%future added value";
export type ProjectOrder = "alphabeticalAZ" | "alphabeticalZA" | "byIncludedInBallots" | "mostAwarded" | "mostInBallots" | "shuffle" | "%future added value";
export type RetroPGFRouteQuery$variables = {
  category?: ReadonlyArray<ProjectCategory> | null;
  orderBy: ProjectOrder;
  search?: string | null;
  seed?: string | null;
};
export type RetroPGFRouteQuery$data = {
  readonly retroPGF: {
    readonly projectsAggregate: {
      readonly " $fragmentSpreads": FragmentRefs<"RetroPGFBallotStatusCardFragment" | "RetroPGFMetricsContainer" | "RetroPGFProjectListContentFragment">;
    };
  };
  readonly " $fragmentSpreads": FragmentRefs<"RetroPGFResultsContainerFragment">;
};
export type RetroPGFRouteQuery = {
  response: RetroPGFRouteQuery$data;
  variables: RetroPGFRouteQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "category"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "orderBy"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "seed"
},
v4 = {
  "kind": "Variable",
  "name": "category",
  "variableName": "category"
},
v5 = {
  "kind": "Variable",
  "name": "orderBy",
  "variableName": "orderBy"
},
v6 = {
  "kind": "Variable",
  "name": "search",
  "variableName": "search"
},
v7 = {
  "kind": "Variable",
  "name": "seed",
  "variableName": "seed"
},
v8 = [
  (v4/*: any*/),
  {
    "kind": "Literal",
    "name": "first",
    "value": 30
  },
  (v5/*: any*/),
  (v6/*: any*/),
  (v7/*: any*/)
],
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "address",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "RetroPGFRouteQuery",
    "selections": [
      {
        "args": [
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "RetroPGFResultsContainerFragment"
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "RetroPGF",
        "kind": "LinkedField",
        "name": "retroPGF",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ProjectsAggregate",
            "kind": "LinkedField",
            "name": "projectsAggregate",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "RetroPGFBallotStatusCardFragment"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "RetroPGFMetricsContainer"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "RetroPGFProjectListContentFragment"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "RetroPGFRouteQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "RetroPGF",
        "kind": "LinkedField",
        "name": "retroPGF",
        "plural": false,
        "selections": [
          {
            "alias": "applications",
            "args": (v8/*: any*/),
            "concreteType": "ProjectsConnection",
            "kind": "LinkedField",
            "name": "projects",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ProjectsEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Project",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v9/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "displayName",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "impactCategory",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Delegate",
                        "kind": "LinkedField",
                        "name": "applicant",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Address",
                            "kind": "LinkedField",
                            "name": "address",
                            "plural": false,
                            "selections": [
                              (v10/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "ResolvedName",
                                "kind": "LinkedField",
                                "name": "resolvedName",
                                "plural": false,
                                "selections": [
                                  (v10/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "name",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          (v9/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "OptimistProfile",
                        "kind": "LinkedField",
                        "name": "profile",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "profileImageUrl",
                            "storageKey": null
                          },
                          (v9/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "includedInBallots",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "includedInLists",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "awarded",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": "applications",
            "args": (v8/*: any*/),
            "filters": [
              "orderBy",
              "category",
              "seed",
              "search"
            ],
            "handle": "connection",
            "key": "RetroPGFResultsContainerFragment_applications",
            "kind": "LinkedHandle",
            "name": "projects"
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ProjectsAggregate",
            "kind": "LinkedField",
            "name": "projectsAggregate",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "total",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "collectiveGovernance",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "developerEcosystem",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endUserExperienceAndAdoption",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "opStack",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ffc2539674c5cdc357331039c0a951dc",
    "id": null,
    "metadata": {},
    "name": "RetroPGFRouteQuery",
    "operationKind": "query",
    "text": "query RetroPGFRouteQuery(\n  $orderBy: ProjectOrder!\n  $category: [ProjectCategory!]\n  $seed: String\n  $search: String\n) {\n  ...RetroPGFResultsContainerFragment_2SO9cQ\n  retroPGF {\n    projectsAggregate {\n      ...RetroPGFBallotStatusCardFragment\n      ...RetroPGFMetricsContainer\n      ...RetroPGFProjectListContentFragment\n    }\n  }\n}\n\nfragment NounResolvedLinkFragment on ResolvedName {\n  address\n  ...NounResolvedNameFragment\n}\n\nfragment NounResolvedNameFragment on ResolvedName {\n  address\n  name\n}\n\nfragment RetroPGFBallotStatusCardFragment on ProjectsAggregate {\n  total\n}\n\nfragment RetroPGFMetricsContainer on ProjectsAggregate {\n  total\n}\n\nfragment RetroPGFProjectListContentFragment on ProjectsAggregate {\n  collectiveGovernance\n  developerEcosystem\n  endUserExperienceAndAdoption\n  opStack\n}\n\nfragment RetroPGFResultsContainerApplicationRowFragment on Project {\n  id\n  displayName\n  impactCategory\n  applicant {\n    address {\n      address\n      resolvedName {\n        ...NounResolvedLinkFragment\n      }\n    }\n    id\n  }\n  profile {\n    profileImageUrl\n    id\n  }\n  includedInBallots\n  includedInLists\n  awarded\n  ...RetroPGFShareCardModalContentFragment\n}\n\nfragment RetroPGFResultsContainerFragment_2SO9cQ on Query {\n  retroPGF {\n    applications: projects(first: 30, orderBy: $orderBy, category: $category, seed: $seed, search: $search) {\n      edges {\n        node {\n          id\n          ...RetroPGFResultsContainerApplicationRowFragment\n          __typename\n        }\n        cursor\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n}\n\nfragment RetroPGFShareCardModalContentFragment on Project {\n  id\n  displayName\n  profile {\n    profileImageUrl\n    id\n  }\n  awarded\n}\n"
  }
};
})();

(node as any).hash = "72a4e54e7beb284ba344faa418e589ca";

export default node;
