/**
 * @generated SignedSource<<f3b44f0c30fc74cf47246f53b2d290ac>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type VotePowerRowFragment$data = {
  readonly tokensRepresented: {
    readonly bpsOfDelegatedSupply: number;
    readonly bpsOfQuorum: number;
  };
  readonly " $fragmentType": "VotePowerRowFragment";
};
export type VotePowerRowFragment$key = {
  readonly " $data"?: VotePowerRowFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"VotePowerRowFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "VotePowerRowFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "VotingPower",
      "kind": "LinkedField",
      "name": "tokensRepresented",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "bpsOfDelegatedSupply",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "bpsOfQuorum",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Delegate",
  "abstractKey": null
};

(node as any).hash = "c97b4c75dc70c548558e30d5072d066c";

export default node;
