/**
 * @generated SignedSource<<101946695555baee3942685f685602ec>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RetroPGFProjectListContentFragment$data = {
  readonly collectiveGovernance: number;
  readonly developerEcosystem: number;
  readonly endUserExperienceAndAdoption: number;
  readonly opStack: number;
  readonly " $fragmentType": "RetroPGFProjectListContentFragment";
};
export type RetroPGFProjectListContentFragment$key = {
  readonly " $data"?: RetroPGFProjectListContentFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"RetroPGFProjectListContentFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RetroPGFProjectListContentFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "collectiveGovernance",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "developerEcosystem",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endUserExperienceAndAdoption",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "opStack",
      "storageKey": null
    }
  ],
  "type": "ProjectsAggregate",
  "abstractKey": null
};

(node as any).hash = "5e0b800eda9e5650695e8181ce86b8ba";

export default node;
